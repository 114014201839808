








import { Component, Vue } from "vue-property-decorator";
import Generico from "@/components/Municipalidad/Generico.vue";

@Component({
  components: {
    Generico
  }
})
export default class GenericoView extends Vue {}
